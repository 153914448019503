$(function () {
  let cursorPos = 0;
  let prevCursorPos = 0;

  $(".ruler-wrap").on("mousemove", _.throttle(function (e) {
    cursorPos = e.pageY;
    const translate = Math.round(e.pageY - $(this).offset().top);
    $(".ruler-wrap__track img").css({ "transform": "translateY(calc(-50% + " + translate + "px)" });
  }, 100));

  $(window).on("scroll", _.throttle(function (e) {
    if (prevCursorPos != $(document).scrollTop()) {
      cursorPos -= prevCursorPos;
      prevCursorPos = $(document).scrollTop();
      cursorPos += prevCursorPos;
    }
    const translate = Math.round(cursorPos - $(".ruler-wrap").offset().top);
    $(".ruler-wrap__track img").css({ "transform": "translateY(calc(-50% + " + translate + "px)" });
  }, 100));
});