$(function () {
	/**********************************************************************************************/
	/**********************************          HEADER          **********************************/
	/**********************************************************************************************/
	$(".header__burger").on("click", function () {
		$("body").toggleClass("no-scroll");
		$(".header").toggleClass("show-menu");
	});

	/**********************************************************************************************/
	$(".header__menu .menu-item a").on("click", function () {
		$("body").removeClass("no-scroll");
		$(".header").removeClass("show-menu");
	});




	/**********************************************************************************************/
	/**********************************          SCROLL          **********************************/
	/**********************************************************************************************/
	$(".scroll-link").on("click", function (e) {
		e.preventDefault();
		const DELTA_OFFSET = 20;
		const offset = $($(this).attr("href")).offset().top - $(".header").height() - DELTA_OFFSET;
		$("html").animate({ scrollTop: offset + 'px' }, "300");
	});




	/**********************************************************************************************/
	/**********************************           POPUP          **********************************/
	/**********************************************************************************************/
	$(".popup__overlay, .popup__close").on("click", function (e) {
		e.preventDefault();
		$(".popup.show").removeClass("show");
		$("body").removeClass("no-scroll");
	});

	/**********************************************************************************************/
	$("body").on("click", ".popup-open", function (e) {
		e.preventDefault();
		$("body").addClass("no-scroll");
		$($(this).attr("href")).addClass("show");
	});




	/**********************************************************************************************/
	/**********************************         FORMAREA         **********************************/
	/**********************************************************************************************/
	$(".formarea .formarea__input").each(function () {
		$(this).on("input", function () {
			$(this).toggleClass("is-empty", $(this).val().length == 0);
		});
	});




	/**********************************************************************************************/
	/**********************************       CONTACT FORM       **********************************/
	/**********************************************************************************************/
	$("#contact-form").each(function () {
		$(this).validate({
			submitHandler: function (form) {
				$(form).find(".formarea__submit").addClass("disabled");

				var data = new FormData();
				data.append('name', $(form).find("[name='cf-name']").val());
				data.append('email', $(form).find("[name='cf-email']").val());
				data.append('phone', $(form).find("[name='cf-phone']").val());
				data.append('message', $(form).find("[name='cf-message']").val());

				$.ajax({
					method: 'POST',
					url: $(form).attr("action"),
					enctype: 'multipart/form-data',
					cache: false,
					contentType: false,
					processData: false,
					data: data,
					success: function (data) {
						data = JSON.parse(data);
						$(form).find(".formarea__submit").removeClass("disabled");
						$(form).find(".formarea__response").removeClass("error").removeClass("hide").text(data.response);
						$(form)[0].reset();
						$(form).find(".formarea__input").addClass("is-empty");
						setTimeout(() => {
							$(form).find(".formarea__response").addClass("hide").text("");
						}, 5000);
					},
					error: function (jqXHR, textStatus, errorThrown) {
						console.log(textStatus, errorThrown);
						$(form).find(".formarea__submit").removeClass("disabled");
						$(form).find(".formarea__response").addClass("error").removeClass("hide").text(errorThrown);
						setTimeout(() => {
							$(form).find(".formarea__response").removeClass("error").addClass("hide").text("");
						}, 5000);
					},
				});
			},
		});
	});
});