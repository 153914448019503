$(function () {
  function isInViewport($target) {
    const topDelta = 20;
    const viewport = { top: $(window).scrollTop() };
    viewport.bottom = viewport.top + $(window).height();

    const bounds = $target.offset();
    bounds.bottom = bounds.top + $target.outerHeight();

    return viewport.bottom > bounds.top && viewport.top < bounds.bottom - topDelta;
  };

  if ($(".block-card").length) {
    $(window).on("load scroll", function () {
      $(".block-card").each(function () {
        const $card = $(this);

        if (isInViewport($card)) {
          if (!$card.hasClass("init")) {
            const $timeValue = $card.find(".card-time__value");
            const ITERATIONS = 30;

            function generateNumber(i) {
              const timeDelay = Math.round(100 + 50 * Math.random());
              let timeValue = Math.round(1 + 20 * Math.random());
              if (timeValue < 10) timeValue = "0" + timeValue;
              $timeValue.text(timeValue);
              setTimeout(() => i < ITERATIONS ? generateNumber(++i) : $timeValue.text($timeValue.data("value")), timeDelay);
            }
            generateNumber(0);
          }
          $card.addClass("init");
        }
      });
    });
  }
});