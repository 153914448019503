$(function () {
    /**********************************************************************************************/
    /**********************************          MARQUEE         **********************************/
    /**********************************************************************************************/
    $(".page-home__marquee").marquee({
        duration: 15000,
        delayBeforeStart: 0,
        direction: 'left',
        duplicated: true
    });
});